import { IDropdownItem } from '@doseme/cohesive-ui'

import { IFormField } from '../../../../../../types/validation'
import { isRequired } from '../../../../../../utils/validation/rules'

export const addHospitalFields = (): Record<string, IFormField> => {
  const initialNameValue = '' // form input is of type string
  const initialTimezoneValue = '' // form input is of type string

  return {
    name: {
      // Input - string
      // Value - number
      initialInput: initialNameValue,
      rules: [isRequired]
    },
    timezone: {
      // Input - string
      // Value - number
      initialInput: initialTimezoneValue,
      rules: [isRequired]
    }
  }
}

export const hospitalUnits: IDropdownItem[] = [{
  value: '1',
  label: 'US units'
}, {
  value: '0',
  label: 'Non-US units'
}]
