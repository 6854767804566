import { makeAutoObservable } from 'mobx'

import { IFilterItem } from '../../types'
import { AdminClinicianListStore } from './AdminClinicianListStore'

export class AdminClinicianFilterItem implements IFilterItem {
  store: AdminClinicianListStore

  id?: string
  type: string
  attributes: IFilterItem['attributes']

  constructor(store: AdminClinicianListStore, entity: IFilterItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
