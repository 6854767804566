import { DatePickerInput, Dropdown, ITimeState, TextInput, TimeInput, ValidationMessage } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { createRef, useEffect, useState } from 'react'
import classnames from 'classnames'

import { Course } from '../../../../../../../../../store/course/Course'
import { useFormValidation } from '../../../../../../../../../hooks/useFormValidation'
import { ICourseAdministrationType } from '../../../../../../../../../store/course/types'
import { doseFormFields } from './constants'
import { getCurrentAdministrationUnits } from './utils'

import './index.scss'

interface IProps {
  id: number
  patientId: string
  course: Course
  hospitalTimezone: string
  inputs?: Record<string, any>
  administrationTypesMap: Map<string, ICourseAdministrationType>
  returnForm: (id: number, getValues: any) => void
}

export const AddDoseForm: React.FC<IProps> = observer((props) => {
  const [blur, setBlur] = useState<boolean>(false)
  const [drugId, setDrugId] = useState<string>([...props.administrationTypesMap][0][0])

  const infusionLengthUnitName = props.course.attributes.limits.infusionLength.default.unit?.name

  const formFields =
    doseFormFields(
      props.hospitalTimezone,
      drugId,
      props.course.attributes.drugModel.id,
      props.course.attributes.limits,
      props.administrationTypesMap,
      props.inputs
    )

  const form = useFormValidation(formFields)

  useEffect(() => {
    props.returnForm(props.id, form)
  }, [form.values, form.valid, props.id])

  const hhInputRef = createRef<HTMLInputElement>()
  const mmInputRef = createRef<HTMLInputElement>()

  return (
    <div className='add-administration-modal'>
      {props.administrationTypesMap.size > 1 && (
        <div className='molecule-picker-input'>
          <Dropdown
            label='Drug:'
            fieldState='valid'
            data-testid='molecule-input'
            value={form.inputs['administrationType']}
            placeholder='Select an item'
            data={[...props.administrationTypesMap].map((x) => ({ value: x[1].id, label: x[1].name }))}
            onSelect={(item) => {
              setBlur(true)
              form.validateFields(
                [
                  {
                    field: 'administrationType',
                    input: item.value
                  },
                  {
                    field: 'amount',
                    input: ''
                  }
                ],
                'updateFieldsDisplay'
              )
              setDrugId(item.value)
            }}
          />
        </div>
      )}
      <div>
        <div className='admin-date-time-inputs'>
          <div className='date-input'>
            <DatePickerInput
              label='Date & time (00:00-23:59):'
              fieldState={form.getValidState('dateAdministered')}
              hasValidation={false}
              value={form.inputs['dateAdministered']}
              onChange={(value) =>
                form.validateFields(
                  [
                    {
                      field: 'dateAdministered',
                      input: value
                    },
                    {
                      field: 'timeAdministered',
                      input: form.inputs['timeAdministered'],
                      constraints: {
                        date: value,
                        tz: props.hospitalTimezone
                      }
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
              testId='date-input'
            />
          </div>
          <div className='time-modal-input'>
            <TimeInput
              label={<span>&#8205;</span>}
              fieldState={form.getValidState('timeAdministered')}
              hasValidation={false}
              testIdHh='time-input-hh'
              testIdMm='time-input-mm'
              data-testid='time-input'
              value={form.inputs['timeAdministered']}
              hhInputRef={hhInputRef}
              mmInputRef={mmInputRef}
              onChange={(value: ITimeState) => {
                form.validateFields([
                  {
                    field: 'timeAdministered',
                    input: value,
                    constraints: {
                      date: form.inputs['dateAdministered'],
                      tz: props.hospitalTimezone
                    }
                  }
                ])
              }}
              onBlur={() => {
                {
                  form.updateFieldsDisplay(['timeAdministered'])
                }
              }}
            />
          </div>
        </div>
        <ValidationMessage
          labelState='error'
          message={form.getValidationMsg('dateAdministered') || form.getValidationMsg('timeAdministered')}
        />
      </div>
      <div
        className={classnames('admin-panel-dose-amount-input', {
          'long-units':
            getCurrentAdministrationUnits(props.administrationTypesMap, form.inputs['administrationType']).name.length >
            3
        })}
      >
        <TextInput
          label='Dose amount:'
          // When a new dropdown option is selected the text is wiped. Do not validate in this case
          fieldState={blur ? 'valid' : form.getValidState('amount')}
          validationText={blur ? '' : form.getValidationMsg('amount')}
          value={!isNaN(form.inputs['amount']) ? form.inputs['amount'] : ''} // Needed because of pre-filled data (could be NaN)
          name='amount-input'
          onChange={(value) =>
            form.validateFields([
              {
                field: 'amount',
                input: value,
                constraints: formFields.amount.initialConstraints
              }
            ])
          }
          onBlur={() => {
            setBlur(false)
            form.updateFieldsDisplay(['amount'])
          }}
          units={getCurrentAdministrationUnits(props.administrationTypesMap, form.inputs['administrationType']).name}
        />
      </div>
      {/* The below is to determine whether the course drug model is Docetaxel and the admin selected isn't */}
      {props.course.attributes.drugModel.id !== '54' || form.inputs['administrationType'] === '54' ? (
        <div className='admin-panel-infusion-length-input'>
          <TextInput
            label='Infusion length:'
            fieldState={form.getValidState('infusionLength')}
            validationText={form.getValidationMsg('infusionLength')}
            value={!isNaN(form.inputs['infusionLength']) ? form.inputs['infusionLength'] : ''} // Needed because of pre-filled data (could be NaN)
            name='infusion-length-input'
            onChange={(value) =>
              form.validateFields([
                {
                  field: 'infusionLength',
                  input: value,
                  constraints: formFields.infusionLength.initialConstraints
                }
              ])
            }
            onBlur={() => form.updateFieldsDisplay(['infusionLength'])}
            units={infusionLengthUnitName}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
})
