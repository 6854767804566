import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { decode } from 'he'
import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'

import { useAdminHospitalDetailsStore, useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { IDrugDetailsModalProps } from '../../types'
import { buildFormFields, buildInputs } from '../../../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { IPutAdminHospitalDrugGeneralModelSettings } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { arrangeKeysInOrder } from '../../utils'

import '../../index.scss'

export const GeneralSettingsModal: React.FC<IDrugDetailsModalProps> = observer((props) => {
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show && props.drugId) {
      adminHospitalDrugDetailsStore.fetchAdminHospitalDrugGeneralModelSettings(props.hospitalId, props.drugId)
    }
  }, [props.hospitalId, props.show])

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings)) {
      return buildFormFields(
        adminHospitalDrugDetailsStore.repackageResponseForBuilding(
          adminHospitalDrugDetailsStore.adminHospitalDrugGeneralModelSettings?.attributes.settings.general
        )
      )
    }

    return {}
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings])

  const form = useFormValidation(formFields)

  const formContent = (): JSX.Element => {
    const displayOrder = [
      'dosingMethod',
      'doseUnit',
      'doseRoundingUnit',
      'doseDefaultAmount',
      'observationLevelUnit',
      'aucMeasurementUnit'
    ]
    const data = adminHospitalDrugDetailsStore.adminHospitalDrugGeneralModelSettings?.attributes.settings.general
    const dataKeys = data ? arrangeKeysInOrder(Object.keys(data), displayOrder) : []

    if (adminHospitalDrugDetailsStore.adminHospitalDrugGeneralModelSettings?.attributes.settings.general) {
      return (
        <div className='position-relative w-100'>
          {buildInputs(
            adminHospitalDrugDetailsStore.repackageResponseForBuilding(
              adminHospitalDrugDetailsStore.adminHospitalDrugGeneralModelSettings.attributes.settings.general
            ),
            form,
            dataKeys,
            formFields
          )}
        </div>
      )
    }

    return <></>
  }

  // As this is an edit form, show all validation statuses for fields on initial render
  useEffect(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings])

  const handleSubmit = async () => {
    if (props.drugId && adminHospitalDrugDetailsStore.adminHospitalDrugGeneralModelSettings) {
      const responseStructure = {
        name: adminHospitalDrugDetailsStore.adminHospitalDrugGeneralModelSettings.attributes.name,
        settings: {
          general: {
            dosingMethod: form.values.dosingMethod?.toString() || null,
            doseUnit: form.values.doseUnit?.toString() || null,
            doseRoundingUnit: form.values.doseRoundingUnit ? parseFloat(form.values.doseRoundingUnit) : null,
            doseDefaultAmount: form.values.doseDefaultAmount ? parseFloat(form.values.doseDefaultAmount) : null,
            observationLevelUnit: undefined,
            aucMeasurementUnit: undefined
          }
        }
      }
      const data = adminHospitalDrugDetailsStore.adminHospitalDrugGeneralModelSettings?.attributes.settings.general
      if (data.aucMeasurementUnit) {
        responseStructure.settings.general.aucMeasurementUnit
          = form.values.aucMeasurementUnit?.toString() || null
      }
      if (data.observationLevelUnit) {
        responseStructure.settings.general.observationLevelUnit
          = form.values.observationLevelUnit?.toString() || null
      }

      await adminHospitalDrugDetailsStore.putAdminHospitalDrugGeneralModelSettings(
        props.hospitalId,
        props.drugId,
        responseStructure as IPutAdminHospitalDrugGeneralModelSettings
      )
      if (
        ['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings)
      ) {
        showErrorToast(
          adminHospitalDrugDetailsStore.settingsErrors.generalModelSettings || 'Failed to update general model settings'
        )

        return
      }
      showSuccessToast('General model settings updated')
      props.setShow(false)
    }
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid}
              loading={['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings)}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='General model settings'
        subtitle={
          <div className='hospital-drug-model-settings-modal-subtitle'>
            <b>{adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.name}</b>
            <div className='mt-2'>{decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}</div>
          </div>
        }
        message={formContent()}
      />
    </Modal>
  )
})
