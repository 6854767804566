import { Modal, InfoModal, Button } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { stringify } from 'query-string'
import pluralize from 'pluralize'

import { useAuthStore, useHospitalStore, usePatientStore } from '../../../../hooks/useStore'
import {
  receiveEpicToken,
  requestEpicHandshake
} from '../../../OpenWindowEpic'
import { IModalProps } from '../../../PatientRouter/types'
import { formatExternalLinkDivs } from '../../../ResourcesRouter/utils'

import './index.scss'

export const HelpRequestModal: React.FC<IModalProps> = observer((props) => {
  const patientStore = usePatientStore()
  const hospitalStore = useHospitalStore()
  const authStore = useAuthStore()

  const [token, setToken] = useState<string>()

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'epic') {
      requestEpicHandshake(receiveEpicToken, setToken)
    }
  }, [])

  const linkComponent = (): JSX.Element => {
    const clinicianName = `${authStore.auth?.attributes.clinicianFirstName} ${authStore.auth?.attributes.clinicianLastName}`

    const supportParams = stringify({
      vendor: window.env.VENDOR_MODE,
      clinicianName,
      clinicianId: authStore.auth?.attributes.clinicianId,
      dosemePatientId: patientStore.patient?.id || '',
      instance: window.env.INSTALLATION || '',
      dosemeHospitalId: hospitalStore.hospital?.id || '',
      dosemeHospitalName: hospitalStore.hospital?.attributes.name || ''
    })

    const helpRequestLink = `https://doseme-rx.com/why-dosemerx/support/sof-support?chat=show&${supportParams}`

    return formatExternalLinkDivs(helpRequestLink, token,
      <Button variant='primary'>Start Chat &#8599;</Button>
    )
  }

  const patientDetails = () => {
    const patientNameAgeDisplay = () => {
      if (
        patientStore.patient &&
        patientStore.patient.attributes.familyName &&
        patientStore.patient.attributes.givenNames
      ) {
        const attributes = patientStore.patient.attributes
        const ageUnit: string = attributes.age.unit!.name

        return (
          <>
            <span className='font-bold'>
              {attributes.familyName}, {attributes.givenNames}{' '}
            </span>
            ({attributes.age.value} {pluralize(ageUnit, attributes.age.value)} old)
          </>
        )
      }

      return <>Unable to retrieve patient name</>
    }

    if (window.env.VENDOR_MODE === 'epic') {
      return patientNameAgeDisplay()
    }

    return (
      <>
        {patientStore.patient && patientStore.patient.attributes.longId
          ? 'ID: ' + patientStore.patient.attributes.longId
          : null}
        <br />
        {patientNameAgeDisplay()}
      </>
    )
  }

  const formContent: JSX.Element = (
    <div className='d-block'>
      <div className='help-request-title'>Patient Details</div>
      <div className='help-request-subtitle'>You are requesting help with the following patient:</div>

      <div className='help-request-patient-details'>{patientDetails()}</div>
      <div className='help-request-confirmation-message'>
        DoseMe support staff will only be able to provide help for the patient identified above. <br />
        <br />
        Please confirm this is the correct patient you are seeking help with.
      </div>
    </div>
  )

  const formContentNoPatientDetails: JSX.Element = (
    <div className='d-block'>
      <div className='help-request-title'>Patient Details</div>
      <div className='help-request-subtitle'>You are requesting help with the following patient:</div>

      <div className='help-request-patient-details'>{patientDetails()}</div>
      <div className='help-request-confirmation-message'>
        DoseMeRx was unable to retrieve patient information, chat to our support staff for assistance. <br />
        <br />
        You may be asked to help us identify the patient, but please be careful not to share any PHI <br />
        <br />
        over live chat.
      </div>
    </div>
  )

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <InfoModal
        size='m'
        linkComponent={linkComponent()}
        title='Patient Help Request'
        message={
          patientStore.loadState === 'loaded' && patientStore.patient ? formContent : formContentNoPatientDetails
        }
        onDismiss={() => props.setShow(false)}
      />
    </Modal>
  )
})
