import { IDropdownItem } from '@doseme/cohesive-ui'

import { Course } from '../../../../../../../../../store/course/Course'
import { IDefaultLimit, ILimit, IUnit } from '../../../../../../../../../store/types'
import { ICourseAdministrationType, ICourseLimits } from '../../../../../../../../../store/course/types'

export const getMoleculeDropdownItems = (course: Course): IDropdownItem[] => {
  const moleculeDropdownItems = course.attributes.administrationTypes.map((administration) => ({
    value: administration.molecule.id,
    label: administration.molecule.name
  }))

  return moleculeDropdownItems
}

export const getCurrentAdministrationUnits = (adminTypesMap: Map<string, ICourseAdministrationType>, adminId: string): IUnit => {
  return [...adminTypesMap]
    .filter((x) => adminId === x[0])
    .map((x) => ({
      name: x[1].unit.name,
      id: x[1].unit.id
    }))[0]
}

export const getAddAdminLimits = (
  selectedDrugId: string,
  courseDrugModelId: string,
  courseLimits: ICourseLimits,
  adminTypes?: Map<string, ICourseAdministrationType> | null
): { amountConstraints: IDefaultLimit | ILimit | null; infusionLengthConstraints: IDefaultLimit | null } => {
  if (courseDrugModelId === selectedDrugId) {
    return { amountConstraints: courseLimits.dose, infusionLengthConstraints: courseLimits.infusionLength }
  }

  if (adminTypes) {
    const currentAdminType = adminTypes.get(selectedDrugId)

    if (currentAdminType && currentAdminType.limits) {
      return { amountConstraints: currentAdminType.limits.doseClinical, infusionLengthConstraints: null }
    }
  }

  return { amountConstraints: null, infusionLengthConstraints: null }
}
