import { observer } from 'mobx-react-lite'
import { ActionButton, SidePanel } from '@doseme/cohesive-ui'

import { IDrugDetailsPanelWithModalProps, TDrugSettingsMap } from '../../types'
import { useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { arrangeKeysInOrder } from '../../utils'

import '../../index.scss'

export const GeneralModelSettings: React.FC<IDrugDetailsPanelWithModalProps> = observer((props) => {
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()

  const displayPanel = (data: TDrugSettingsMap): JSX.Element[] => {
    const displayOrder = [
      'dosingMethod',
      'doseUnit',
      'doseRoundingUnit',
      'doseDefaultAmount',
      'observationLevelUnit',
      'aucMeasurementUnit'
    ]
    const dataKeys = arrangeKeysInOrder(Object.keys(data), displayOrder)
    let returnElements: JSX.Element[] = []

    dataKeys.forEach((key, index) => {
      returnElements.push(
        <div key={key} className='no-modal-panel-data-row'>
          <div className='no-modal-panel-title-row'>
            <b>{`${data[key].label}`}</b>
            <div className='no-modal-panel-data-row-column-1'>
              {data[key].unit ? `${data[key].value} ${data[key].unit.name}` : data[key].value}
            </div>
          </div>
        </div>
      )

      if (index !== dataKeys.length - 1) {
        returnElements.push(<hr key={`${key}-line`}></hr>)
      }
    })

    return [
      <div key='drug-settings-outer' className='display-formatted-details'>
        {returnElements}
      </div>
    ]
  }

  return (
    <SidePanel
      title='General model settings'
      loading={
        ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.loadState)
        || ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings)
      }
      actionButton={
        adminHospitalDrugDetailsStore.loadState === 'loaded' && props.data
          ? (
            <ActionButton
              data-testid='hospital-drug-details-data-btn'
              actionType='edit'
              onClick={() => props.setShow(true)}
            />
          )
          : undefined
      }
    >
      {props.data && displayPanel(props.data)}
    </SidePanel>
  )
})
