import { ObservableMap, makeAutoObservable, observable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import {
  adminClinicianBulkDisablePutUrl,
  adminClinicianBulkEnablePutUrl,
  adminClinicianListGetUrl,
  adminClinicianResendInviteGetUrl,
  adminClinicianInviteDeleteUrl,
  adminClinicianListFiltersGetUrl
} from '../../../constants/api'
import { RootStore } from '../../RootStore'
import { IFilterItem, TLoadState } from '../../types'
import { IAdminClinicianListItem } from './types'
import { AdminClinicianListItem } from './AdminClinicianListItem'
import { AdminClinicianFilterItem } from './AdminClinicianFilterItem'

export class AdminClinicianListStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  filterLoadState: TLoadState = 'initial'
  error: string = ''

  adminClinicians: ObservableMap<string, IAdminClinicianListItem> = observable.map({}, { deep: false })
  adminClinicianFilters: ObservableMap<string, IFilterItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  resetAdminClinicianFilters(adminClinicianFilters: IFilterItem[]) {
    this.adminClinicianFilters = observable.map({}, { deep: false })
    adminClinicianFilters.forEach((adminClinicianFilter) => this.adminClinicianFilters.set(adminClinicianFilter.attributes.name, new AdminClinicianFilterItem(this, adminClinicianFilter)))
  }

  setFilterLoadState(filterLoadState: TLoadState) {
    this.filterLoadState = filterLoadState
  }

  resetAdminClinicians(adminClinicians: IAdminClinicianListItem[]) {
    this.adminClinicians = observable.map({}, { deep: false })
    adminClinicians.forEach((clinician) =>
      this.adminClinicians.set(clinician.id, new AdminClinicianListItem(this, clinician))
    )
  }

  setAdminClinicians(adminClinicians: IAdminClinicianListItem[]) {
    adminClinicians.forEach((clinician) =>
      this.adminClinicians.set(clinician.id, new AdminClinicianListItem(this, clinician))
    )
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchClinicianListFilters() {
    this.setFilterLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IFilterItem[]>>(adminClinicianListFiltersGetUrl(), { headers })
      .then((response) => {
        this.resetAdminClinicianFilters(response.data.data)
        this.setFilterLoadState('loaded')
      })
      .catch((error) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async fetchAdminClinicians(roleFilter?: string[], hospitalFilter?: string[]) {
    this.setLoadState('loading')

    const params = new URLSearchParams()
    roleFilter?.forEach((role) => {
      params.append('role', role)
    })
    hospitalFilter?.forEach((hospital) => {
      params.append('hospital', hospital)
    })

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminClinicianListItem[]>>(adminClinicianListGetUrl(), { headers, params })
      .then((response: AxiosResponse) => {
        this.resetAdminClinicians(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async enableAdminClinicians(clinicianIds: string[]): Promise<IAdminClinicianListItem[] | void> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .put<AxiosResponse<IAdminClinicianListItem[]>>(
        adminClinicianBulkEnablePutUrl(),
        {
          data: {
            type: 'adminCliniciansBulkEnable',
            attributes: {
              clinicianIds: clinicianIds
            }
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setAdminClinicians(response.data.data)
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }

  async disableAdminClinicians(clinicianIds: string[]): Promise<IAdminClinicianListItem[] | void> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .put<AxiosResponse<IAdminClinicianListItem[]>>(
        adminClinicianBulkDisablePutUrl(),
        {
          data: {
            type: 'adminCliniciansBulkDisable',
            attributes: {
              clinicianIds: clinicianIds
            }
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setAdminClinicians(response.data.data)
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
      })
  }

  async resendAdminClinicianInvite(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get(adminClinicianResendInviteGetUrl(clinicianId), { headers })
      .then(() => {
        this.setLoadState('loaded')
        this.fetchAdminClinicians()
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async cancelAdminClinicianInvite(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .delete(adminClinicianInviteDeleteUrl(clinicianId), { headers })
      .then(() => {
        this.setLoadState('loaded')
        this.fetchAdminClinicians()
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
