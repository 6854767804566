import { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Skeleton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useIntercom } from 'react-use-intercom'

import axiosClient, { AxiosResponse } from '../../utils/axiosClient'
import {
  useAuthStore,
  useConstantsTimezoneStore,
  useErrorsStore,
  usePasswordRulesStore
} from '../../hooks/useStore'
import { LoginModal } from './components/LoginModal'

interface IProps {
  patientId?: string
}

const StandaloneSessionMiddleware: React.FC<IProps> = observer((props) => {
  const errorsStore = useErrorsStore()
  const authStore = useAuthStore()
  const passwordRulesStore = usePasswordRulesStore()
  const timezoneStore = useConstantsTimezoneStore()
  const { update } = useIntercom()

  const history = useHistory()

  const [showLogin, setShowLogin] = useState<boolean>(false)
  const [sessionExpiryEpoch, setSessionExpiryEpoch] = useState<number | null>(null)
  const [lastActivityEpoch, setLastActivityEpoch] = useState<number | null>(null)

  useEffect(() => {
    // Only trigger session refresh once on page load
    if (authStore.loadState === 'initial') {
      refreshDoseMeSession()
    }

    // Store the interceptor to remove it later
    const responseInterceptor = axiosClient.interceptors.response.use((response: AxiosResponse) => {
      if (response.headers['x-dosemerx-lockout']) {
        const newSessionExpiryEpoch = parseInt(response.headers['x-dosemerx-lockout'])
        if (!isNaN(newSessionExpiryEpoch)) {
          setSessionExpiryEpoch(newSessionExpiryEpoch)
        }
      }

      return response
    })

    return () => {
      axiosClient.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  useLayoutEffect(() => {
    if (authStore.isAuthenticated()) {
      if (timezoneStore.loadState === 'initial') {
        timezoneStore.fetchTimezones()
      }

      if (passwordRulesStore.loadState === 'initial') {
        passwordRulesStore.getPasswordRules()
      }

      // FIXME: IFE-975 Set all Intercom session details from session payload
      // Update the Intercom session with authenticated user details
      if (window.env.INTERCOM_APP_ID && authStore.auth) {
        update({
          name: `${authStore.auth.attributes.clinicianFirstName} ${authStore.auth.attributes.clinicianLastName}`,
          email: authStore.auth.attributes.clinicianEmail,
          userId: `${window.env.INSTALLATION}/${authStore.auth.attributes.clinicianId}`,
          company: {
            companyId: `${window.env.INSTALLATION}/${authStore.auth.attributes.hospitalId}`,
            name: authStore.auth.attributes.hospitalName
          },
          customAttributes: {
            instance: window.env.INSTALLATION,
            vendor: window.env.VENDOR_MODE
          }
        })
      }
    }
  }, [authStore.loadState])

  useEffect(() => {
    // IFE-1105 if the authStore has been reset (or we logged out) or failed to login after timeout
    // redirect to the /login route
    if (authStore.loadState === 'loaded' && authStore.auth === null) {
      history.push('/login')
    }

    if (authStore.loadState === 'loadError' && authStore.error) {
      if (authStore.auth === null) {
        history.push('/login')
      }

      setShowLogin(true)
    }
  }, [authStore.loadState])

  /**
  * Tracks user activity (mouse, key, click, scroll) and updates the last activity time. Used to refresh before timeout
  */
  useEffect(() => {
    const handleActivity = () => {
      const currentEpochInSeconds = Math.floor(Date.now() / 1000)
      setLastActivityEpoch(currentEpochInSeconds)
    }

    const eventsToTrack = ['mousemove', 'keydown', 'click', 'scroll']
    eventsToTrack.forEach((event) => window.addEventListener(event, handleActivity))

    return () => {
      eventsToTrack.forEach((event) => window.removeEventListener(event, handleActivity))
    }
  }, [])

  /**
   * when a new session is created then start the timer for the eventual logout
   */
  useEffect(() => {
    if (sessionExpiryEpoch) {
      // lastActivityEpoch is no longer relevant due to the session already being refreshed
      setLastActivityEpoch(null)

      const now = moment()
      // trigger 5 seconds before timeout
      const logoutTimeoutAfter = sessionExpiryEpoch * 1000 - now.valueOf() - 5000
      // trigger 30 seconds before timeout
      const refreshTimeoutAfter = sessionExpiryEpoch * 1000 - now.valueOf() - 30000

      const logoutTimer = setTimeout(() => {
        setShowLogin(true)
      }, logoutTimeoutAfter)

      const refreshTimer = setTimeout(() => {
        if (lastActivityEpoch) {
          refreshDoseMeSession(lastActivityEpoch)
        }
      }, refreshTimeoutAfter)

      return () => {
        clearTimeout(logoutTimer)
        clearTimeout(refreshTimer)
      }
    }
  }, [sessionExpiryEpoch])

  const refreshDoseMeSession = async (lastActivityEpoch?: number) => {
    await authStore.authSession(props.patientId, lastActivityEpoch)

    const patientRelatedErrors = [
      'Invalid patient ID submitted.',
      'Clinician does not have required access to requested patient.'
    ]

    // If the session error is patient related, re-trigger auth session without patient context
    if (authStore.error && patientRelatedErrors.includes(authStore.error)) {
      await authStore.authSession(undefined, lastActivityEpoch)
    }

    // authStore.auth?.attributes.is2faAuthenticated indicates whether 2fa is required and authenticated
    // undefined = not required
    // false = required, not authenticated
    // true = required, authenticated
    if (!authStore.isAuthenticated()) {
      history.push('/login')
    } else if (authStore.auth?.attributes.clinicianEmail) {
      authStore.authCheckSSO(authStore.auth?.attributes.clinicianEmail)
    }
  }

  // Throw anything pushed into the errorStore into the ErrorContentBoundary
  if (errorsStore.hasErrors()) {
    throw errorsStore.lastError()
  }

  if (!showLogin &&
    (authStore.loadState === 'initial' ||
      !authStore.auth ||
      passwordRulesStore.loadState !== 'loaded')
  ) {
    return (
      <div className='h-100'>
        <Skeleton.InitialLoad />
      </div>
    )
  }

  return (
    <div className='h-100'>
      <LoginModal show={showLogin} setShow={setShowLogin} />
      {props.children}
    </div>
  )
})

export { StandaloneSessionMiddleware }
