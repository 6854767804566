import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import { useEffect } from 'react'
import { Skeleton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { useAuthStore, useClinicianStore, useHospitalStore } from '../../hooks/useStore'
import { HospitalPatientListPage } from './components/PatientList/HospitalPatientListPage'
import { AddPatient } from './components/AddPatient/AddPatient'
import { PatientRouter } from '../PatientRouter'

interface IPatientProfileMatchParams {
  patientId: string
}

export const PatientsRouter: React.FC = observer((props) => {
  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()
  const clinicianStore = useClinicianStore()

  useEffect(() => {
    if (clinicianStore.loadState !== 'loaded' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [])

  useEffect(() => {
    if (hospitalStore.loadState === 'initial') {
      hospitalStore.fetchHospital(authStore.auth!.attributes.hospitalId)
    }
  }, [hospitalStore.loadState])

  if (hospitalStore.loadState === 'initial' || hospitalStore.loadState === 'loading') {
    return (
      <>
        <Skeleton.InitialPage showConnectionStatus={window.env.VENDOR_MODE !== 'standalone'} />
      </>
    )
  }

  if (window.env.APP_MODE === 'integrated'
    || (window.env.VENDOR_MODE !== 'standalone' && !clinicianStore.clinician?.attributes.isSuperAdmin)) {
    return (
      <div>
        <Switch>
          <Route
            exact
            path='*'
            render={() =>
              <Redirect to={{ pathname: '/login' }} />
            }
          />
        </Switch >
      </div >
    )
  }

  return (
    <div id='hospital-wrapper'>
      {/* Switch base is /hospitals */}
      <Switch>
        {/* Patient List Page (new) */}
        <Route
          exact
          path='/patients'
          render={({ match }: RouteComponentProps) => (
            <HospitalPatientListPage />
          )}
        />

        {/* New Patient Page */}
        <Route
          exact
          path='/patients/new'
          render={() => (
            <AddPatient />
          )}
        />

        {/* Patient Router */}
        <Route
          path='/patients/:patientId'
          render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
            <PatientRouter patientId={match.params.patientId} />
          )}
        />

        {/* Redirect */}
        <Route
          exact
          path='*'
          render={() =>
            <Redirect to={{ pathname: '/login' }} />
          }
        />
      </Switch>
    </div>
  )
})
