import { Col, Container, Row, ControlButton, Modal, InfoModal, BackArrowButton, IBreadcrumb } from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useScreenSize from 'use-screen-size'
import { decode } from 'he'

import { useBreadcrumbsStore, useHospitalStore, usePatientStore } from '../../../../hooks/useStore'
import { Header } from '../Header'
import { handleBackButton } from '../../../../utils/navigation'
import { CourseList } from './components/CourseList'
import { CoursePreviewPanel } from './components/CoursePreviewPanel'

import './index.scss'

interface IProps {
  patientId: string
}

const PatientProfile: React.FC<IProps> = observer(({ patientId }) => {
  const history = useHistory()
  const size = useScreenSize()

  const [selectedCourseId, setSelectedCourseId] = useState<string | undefined>('')
  const [showModal, setShowModal] = useState<boolean>(false)

  const hospitalStore = useHospitalStore()
  const patientStore = usePatientStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && patientStore.loadState === 'loaded') {
      const breadcrumbs: IBreadcrumb[] = window.env.APP_MODE === 'standalone'
        ? [
          { label: `Patients at ${decode(hospitalStore.hospital?.attributes.name || '')}`, onClick: () => handleBackButton('/patients', history) }
        ]
        : []
      breadcrumbs.push({
        label: `${decode(patientStore.patient?.attributes.familyName || '')},
        ${decode(patientStore.patient?.attributes.givenNames || '')} (${patientStore.patient?.attributes.longId})`
      })
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [hospitalStore.loadState, patientStore.loadState])

  const handleAddCourseButton = () => {
    history.push(`/patients/${patientId}/courses/new`, { from: `/patients/${patientId}` })
  }

  const isTabletSize = size.width <= 1000

  if (!isTabletSize) {
    return (
      <div>
        <Header
          patientId={patientId}
          isOnline={false}
          showEditButton
        />
        <div className='patient-profile-content d-block'>
          <div className='course-history-header'>
            {window.env.VENDOR_MODE === 'standalone' && (
              <div className='patient-profile-back-btn'>
                <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton('/patients', history)} />
              </div>
            )}
            <span className='course-history-title'>Course List</span>
            <ControlButton icon={faPlus} onClick={handleAddCourseButton}>
              Create new course
            </ControlButton>
          </div>

          <Container fluid={true}>
            <Row>
              <Col key='list-col' width={6}>
                <CourseList
                  patientId={patientId}
                  selectedCourseId={selectedCourseId}
                  setSelectedCourseId={setSelectedCourseId}
                  isTabletSize={isTabletSize}
                />
              </Col>
              <Col data-testid='course-preview-panel' key='preview-col' width={6}>
                <CoursePreviewPanel
                  patientId={patientId}
                  selectedCourseId={selectedCourseId === undefined ? '' : selectedCourseId}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          setSelectedCourseId(undefined)
        }}
        messageOnly={true}
      >
        <InfoModal
          size='m'
          title=''
          message={
            <div data-testid='course-preview-panel' className='w-100'>
              <CoursePreviewPanel
                patientId={patientId}
                selectedCourseId={selectedCourseId === undefined ? '' : selectedCourseId}
              />
            </div>
          }
          onDismiss={() => {
            setShowModal(false)
            setSelectedCourseId(undefined)
          }}
          messageOnly={true}
        />
      </Modal>

      <Header patientId={patientId} isOnline={false} showEditButton />
      <div className='patient-profile-content d-block'>
        <div className='course-history-header'>
          <span className='tablet-view-course-history-title'>Course List</span>
          <ControlButton icon={faPlus} onClick={handleAddCourseButton}>
            Create new course
          </ControlButton>
        </div>

        <Container fluid={true}>
          <Row className='tablet-view-course-list'>
            <Col>
              <CourseList
                patientId={patientId}
                selectedCourseId={selectedCourseId}
                setSelectedCourseId={setSelectedCourseId}
                setShowCoursePreviewModal={setShowModal}
                isTabletSize={isTabletSize}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})

export { PatientProfile }
