import { toast, ToastOptions } from 'react-toastify'

import './index.scss'

export const showToast = (text: string, options: ToastOptions) => {
  toast(text, {
    position: 'top-right',
    autoClose: 5000,
    ...options
  })
}

export const toastMsg = (icon: string, message: string, type?: string): JSX.Element => {
  return (
    // The containing <></> is necessary so that react-toastify doesn't try to inject react props on a DOM-level element such as Container.
    // Without it, the console logs the following error:
    //// Warning: React does not recognize the `toastProps` prop on a DOM element.
    //// If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `toastprops` instead.
    //// If you accidentally passed it from a parent component, remove it from the DOM element.
    <>
      <div className='toast-container'>
        <div className='mr-3'>{icon}</div>
        <div className='col-message'>
          <b>{type ? type + ':' : ''}</b> {message}
        </div>
      </div>
    </>
  )
}

export const showErrorToast = (text: string) => {
  return toast(toastMsg('⚠️', text, 'Error'), { type: 'error', position: 'top-right', autoClose: false })
}

export const showSuccessToast = (text: string) => {
  return toast(toastMsg('🎉️', text, 'Success'), { type: 'success', position: 'top-right' })
}

export const showInfoToast = (text: string) => {
  return toast(toastMsg('ℹ️', text, 'Info'), { type: 'info', position: 'top-right' })
}
