import { IDropdownItem } from '@doseme/cohesive-ui'

import { ICourseAdministrationType, IAdministrationTypeLimits } from '../../../../../../../../../../store/course/types'

export const formatAdministrations = (admins: ICourseAdministrationType[]): IDropdownItem[] => {
  return admins.map((admin) => ({
    value: admin.id.toString(),
    label: admin.name
  }))
}

export const formatNextDoseDayLimits = (min?: number, max?: number): IDropdownItem[] => {
  let returnArr = [{ value: '', label: '' }]
  if (min && max) {
    returnArr = []
    for (let i = min; i <= max; i++) {
      returnArr.push({ value: i.toString(), label: `Day ${i}` })
    }
  }

  return returnArr
}

export const getGCSFAdminLimits = (
  currentAdministrationTypeId?: string,
  adminTypes?: ICourseAdministrationType[] | null
): IAdministrationTypeLimits | null => {
  if (adminTypes && currentAdministrationTypeId) {
    const currentAdminType = adminTypes.filter((adminType) => {
      return adminType.id === currentAdministrationTypeId
    })

    return currentAdminType.length === 1 && currentAdminType[0].limits
      ? currentAdminType[0].limits
      : null
  }

  return null
}
