import { useEffect, useLayoutEffect, useState } from 'react'
import { Skeleton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { AUTH_CHECK_URL } from '../../constants/api'
import { IAuth } from '../../store/auth/types'
import { useAuthStore, useConstantsTimezoneStore, useErrorsStore } from '../../hooks/useStore'
import { DebugError } from '../AppInsightsErrorBoundary'
import { LoginModal } from './components/LoginModal'
import { buildSearchParams } from '../../store/utils'

interface IProps {
  patientId?: string
}

const IntegratedSessionMiddleware: React.FC<IProps> = observer((props) => {
  const errorsStore = useErrorsStore()
  const authStore = useAuthStore()
  const timezoneStore = useConstantsTimezoneStore()

  const [errorState, setErrorState] = useState<string>('')
  const [authRefresh, setAuthRefresh] = useState<number | null>(null)
  const [showLogin, setShowLogin] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (props.patientId) {
      authStore.authCheck(props.patientId)

      setAuthRefresh(window.env.AUTH_CHECK_REFRESH_MINUTES * 60 * 1000)
    }
  }, [])

  useLayoutEffect(() => {
    if (authStore.loadState === 'loaded' && authStore.auth && timezoneStore.loadState !== 'loaded') {
      timezoneStore.fetchTimezones()
    }
  }, [authStore.auth, authStore.loadState])

  useEffect(() => {
    if (authRefresh) {
      const refreshSessionInterval = setInterval(() => {
        if (props.patientId && authStore.loadState === 'loaded') {
          refreshDoseMeSession(props.patientId)
        }
      }, authRefresh)

      return () => {
        clearInterval(refreshSessionInterval)
      }
    }
  }, [authRefresh])

  const refreshDoseMeSession = async (patientId: string) => {
    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const params = buildSearchParams({
      patientId: patientId,
      appMode: window.env.APP_MODE
    })

    await axiosClient
      .get<AxiosResponse<IAuth>>(AUTH_CHECK_URL, { headers, params })
      .then(() => { })
      .catch((error: Error | AxiosError) => {
        authStore.setLoadState('loadError')
        setAuthRefresh(null)
        const loggableError = errorsStore.parseLoggableError(error)
        setErrorState(loggableError)
      })
  }

  if (!showLogin && authStore.loadState === 'loadError') {
    throw new DebugError(`User is not Authenticated${errorState ? ' - ' + errorState : ''}`)
  }

  // Throw anything pushed into the errorStore into the ErrorContentBoundary
  if (errorsStore.hasErrors()) {
    throw errorsStore.lastError()
  }

  if (!showLogin && (authStore.loadState === 'initial' || authStore.loadState === 'loading')) {
    return (
      <div className='h-100'>
        <Skeleton.InitialLoad />
      </div>
    )
  }

  return (
    <div className='h-100'>
      <LoginModal show={showLogin} setShow={setShowLogin} />
      {props.children}
    </div>
  )
})

export { IntegratedSessionMiddleware }
