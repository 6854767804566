import { ICourseLimits, ICourseAdministrationType } from '../../../../../../../../store/course/types'
import { IDefaultLimit, ILimit } from '../../../../../../../../store/types'

export const getEditAdminLimits = (
  selectedDrugId: string,
  courseDrugModelId: string,
  courseLimits: ICourseLimits,
  adminTypes?: ICourseAdministrationType[] | null
): { amountConstraints: IDefaultLimit | ILimit | null; infusionLengthConstraints: IDefaultLimit | null } => {
  if (courseDrugModelId === selectedDrugId) {
    return { amountConstraints: courseLimits.dose, infusionLengthConstraints: courseLimits.infusionLength }
  }

  if (adminTypes) {
    const currentAdminType = adminTypes.filter((adminType) => {
      return adminType.id === selectedDrugId
    })

    if (currentAdminType.length === 1 && currentAdminType[0].limits) {
      return { amountConstraints: currentAdminType[0].limits.doseClinical, infusionLengthConstraints: null }
    }
  }

  return { amountConstraints: null, infusionLengthConstraints: null }
}
