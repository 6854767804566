import { ObservableMap } from 'mobx'
import { decode } from 'he'
import { IMenuItem, ISelectedRows } from '@doseme/cohesive-ui'

import { TAdminCliniciansFilter } from './types'
import { IAdminClinicianListItem } from '../../../../store/Admin/AdminClinicianList/types'
import { IFilterItem, IFilterResourceListItem, IFilterSubItem } from '../../../../store/types'

export const searchClinicians = (
  searchText: string,
  clinicians: ObservableMap<string, IAdminClinicianListItem>,
  selectedAdminClinicianListTab: TAdminCliniciansFilter
): IAdminClinicianListItem[] => {
  const doesMatchSearch = (clinicianAttributes: IAdminClinicianListItem['attributes'], searchText: string) => {
    return !searchText ||
      clinicianAttributes.name.toLowerCase().includes(searchText.toLowerCase()) ||
      clinicianAttributes.email.toLowerCase().includes(searchText.toLowerCase())
  }

  return [...clinicians].reduce((acc: IAdminClinicianListItem[], [key, curr]) => {
    // Search condition
    if (doesMatchSearch(curr.attributes, searchText)) {
      // Selected tab condition
      if (
        selectedAdminClinicianListTab === 'enabledClinicians' && curr.attributes.enabled && !curr.attributes.hasPendingInvite ||
        selectedAdminClinicianListTab === 'disabledClinicians' && !curr.attributes.enabled && !curr.attributes.hasPendingInvite ||
        selectedAdminClinicianListTab === 'pendingClinicians' && curr.attributes.hasPendingInvite
      ) {
        return acc.concat({
          id: curr.id,
          type: curr.type,
          attributes: {
            name: curr.attributes.name,
            email: curr.attributes.email,
            enabled: curr.attributes.enabled,
            lastActive: curr.attributes.lastActive,
            twoFASetup: curr.attributes.twoFASetup,
            roles: curr.attributes.roles,
            hasPendingInvite: curr.attributes.hasPendingInvite,
            inviteExpiresAt: curr.attributes.inviteExpiresAt
          }
        })
      }
    }

    return acc
  }, [])
}

export const getClinicianNamesFromIds = (clinicianIds: string[], clinicians: ObservableMap<string, IAdminClinicianListItem>) => {
  return clinicianIds.reduce((acc: string[], key: string) => {
    const name = clinicians.get(key)?.attributes.name
    if (name) {
      return acc.concat(name)
    }

    return acc
  }, [])
}

export const getSelectedClinicianIds = (selectedRows?: ISelectedRows) => {
  if (selectedRows) {
    return Object.keys(selectedRows).reduce((acc: string[], key: string) => {
      if (selectedRows[key]) {
        return acc.concat(key)
      }

      return acc
    }, [])
  }

  return []
}

export const buildFilterItems = (
  clinicianFilterData: IFilterItem[],
  updateFilter: (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem,
  ) => void,
  hospitalFilter: Record<string, string>,
  roleFilter: Record<string, string>
): IMenuItem[] => {
  return clinicianFilterData.map((clinicianFilterItem: IFilterItem) => {
    let selectedCount

    switch (clinicianFilterItem.attributes.name) {
      case 'hospital':
        selectedCount = Object.keys(hospitalFilter).length
        break
      case 'individual role':
        selectedCount = Object.keys(roleFilter).length
        break
    }

    return {
      id: clinicianFilterItem.id,
      name: decode(clinicianFilterItem.attributes.label),
      subItems: buildSubItems(
        clinicianFilterItem.attributes.values,
        updateFilter,
        hospitalFilter,
        roleFilter,
        clinicianFilterItem.attributes.name
      ),
      selectedCount: selectedCount ? selectedCount : undefined
    }
  })
}

const buildSubItems = (
  subItems: Array<IFilterResourceListItem | IFilterSubItem>,
  updateFilter: (
    type: string,
    selected: boolean,
    item: IFilterResourceListItem,
  ) => void,
  hospitalFilter: Record<string, string>,
  roleFilter: Record<string, string>,
  type: string
): IMenuItem[] => {

  return subItems.map<IMenuItem>((subItem: any) => {
    let itemSelected = false
    let selectedCount = 0

    switch (type) {
      case 'hospital':
        itemSelected = hospitalFilter[subItem.id] !== undefined
        break
      case 'individual role':
        itemSelected = roleFilter[subItem.id] !== undefined
        break
    }

    return {
      id: subItem.id,
      name: decode(subItem.name),
      selected: itemSelected,
      onSelect: subItem.values ? undefined : (selected: boolean) => updateFilter(type, selected, subItem),
      selectedCount: selectedCount ? selectedCount : undefined
    }
  })
}
