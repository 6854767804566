export const buildSearchParams = (params: Record<string, string | undefined>) => {
  const searchPrams = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      searchPrams.append(key, value)
    }
  })

  return searchPrams
}
