import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { decode } from 'he'
import { FloatingTabBar, Skeleton } from '@doseme/cohesive-ui'
import { useHistory } from 'react-router-dom'

import { useAnalyticsStore, useAuthStore, useBreadcrumbsStore, useClinicianStore, useHospitalStore } from '../../hooks/useStore'
import { analyticsTabs } from './constants'
import { TAnalyticsTab } from './types'
import { TableauEmbed } from './Components'

import './index.scss'

interface IProps {
  patientId?: string
}

export const AnalyticsPage: React.FC<IProps> = observer((props) => {
  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()
  const breadcrumbsStore = useBreadcrumbsStore()
  const analyticsStore = useAnalyticsStore()
  const clinicianStore = useClinicianStore()

  const history = useHistory()

  const [activeAnalyticsTab, setActiveAnalyticsTab] = useState<TAnalyticsTab>('clinical')

  useEffect(() => {
    if (clinicianStore.loadState !== 'loaded' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [])

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Analytics' }
    ]

    breadcrumbsStore.setBreadcrumbs(breadcrumbs)

    if (hospitalStore.loadState === 'initial') {
      hospitalStore.fetchHospital(authStore.auth!.attributes.hospitalId)
    }

    if (analyticsStore.loadState === 'initial') {
      analyticsStore.fetchAnalyticsDetails(authStore.auth!.attributes.hospitalId)
    }
  }, [])

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital) {
      const breadcrumbs = [
        { label: `Analytics for ${decode(hospitalStore.hospital.attributes.name)}` }
      ]

      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [hospitalStore.loadState])

  const panelContent = () => {
    //FIXME IFE-1007 To be replaced with proper error page
    if (analyticsStore.loadState === 'loadError') {
      throw Error(analyticsStore.error || 'Error fetching analytics details')
    }

    if (!analyticsStore.analytics) {
      return null
    }

    const dashboardPath =
      activeAnalyticsTab === 'clinical'
        ? analyticsStore.analytics.attributes.clinicalDashboard
        : analyticsStore.analytics.attributes.usageDashboard

    return (
      <TableauEmbed
        url={analyticsStore.analytics.attributes.host + dashboardPath}
        token={analyticsStore.analytics.attributes.token}
        hospitalUUID={analyticsStore.analytics.attributes.hospitalUUID}
        hospitalDepartment={analyticsStore.analytics.attributes.hospitalDepartment}
      />
    )
  }

  if (['loading', 'initial'].includes(analyticsStore.loadState)
    || ['initial', 'loading', 'updating'].includes(clinicianStore.loadState)) {
    return (
      <div className='h-100'>
        <Skeleton.InitialLoad />
      </div>
    )
  }

  if (!clinicianStore.canViewAnalyticsHub()) {
    history.push('/login')
  }

  return (
    <div className='analytics-content'>
      <div className='analytics-header ml-2'>
        <span className='analytics-title'>
          Analytics for&nbsp;
          <span className='analytics-hospital-name'>{decode(hospitalStore.hospital?.attributes.name || '')}</span>
        </span>
      </div>

      <div>
        <div className='mb-3 ml-2'>
          <FloatingTabBar
            tabNames={analyticsTabs}
            activeTab={activeAnalyticsTab}
            onSelectTab={(tab) => setActiveAnalyticsTab(tab as TAnalyticsTab)}
          />
        </div>

        <div className='mt-2'>{panelContent()}</div>
      </div>
    </div>
  )
})
